import { defineStore } from 'pinia'
import { ContractTemplate } from '@/types'
import ContractTemplateService from '@/services/contract-template-service'
import { useToast } from 'vue-toastification'

const toast = useToast()

interface ContractState {
    contractTemplates: ContractTemplate[]
}

export const useContractStore = defineStore('contract', {
    state: (): ContractState => {
        return {
            contractTemplates: [],
        }
    },

    actions: {
        async getContractTemplates(hasFileOnly = true) {
            try {
                this.contractTemplates = await ContractTemplateService.getContractTemplates(hasFileOnly)
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },

        async deleteContractTemplate(id: number) {
            try {
                await ContractTemplateService.deleteContractTemplate(id)
                await this.getContractTemplates()
                toast.success('Template deleted')
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },

        async downloadContractTemplate(fileName: string) {
            try {
                await ContractTemplateService.downloadContractTemplate(fileName)
            } catch (err) {
                console.log(err)
                toast.error(`Error: ${err}`)
            }
        },
    },
})
