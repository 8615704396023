import { BaseHttpService } from '@/services/base-http-service'

class ZohoFieldTypeService extends BaseHttpService {
    public async load() {
        const uri = '/zoho-field-types/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ZohoFieldTypeService()
