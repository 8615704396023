import { BaseHttpService } from '@/services/base-http-service'
import { ContractTemplate } from '@/types'

class ContractTemplateService extends BaseHttpService {
    public async getContractTemplateNames(): Promise<string[]> {
        const uri = '/external/contract-templates/names'
        const response = await this.doApiGet<string[]>(uri, true)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getContractTemplates(hasFileOnly = true): Promise<ContractTemplate[]> {
        const uri = `/contract-templates?hasFileOnly=${hasFileOnly}`
        const response = await this.doApiGet<ContractTemplate[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteContractTemplate(id: number): Promise<boolean> {
        const uri = `/contract-templates/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async uploadContractTemplate(formData: FormData, contractTemplateType = ''): Promise<boolean> {
        const uri = `/contract-templates/upload?contractTemplateType=${contractTemplateType}`
        const response = await this.doApiPost<boolean>(uri, formData, false)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createDownloadContract(recordId: string, templateName: string, title: string) {
        const uri = `/external/contracts/create-download-contract`
        const response = await this.download(uri, 'application/rtf', JSON.stringify({
            recordId,
            templateName,
            title,
        }), true)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async downloadContractTemplate(fileName: string): Promise<boolean> {
        const uri = `/contract-templates/download?fileName=${fileName}`
        const response = await this.download(uri, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ContractTemplateService()
