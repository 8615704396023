import { Project, ProjectSprintsAndBacklog, RecordStatus } from '@/types'
import { BaseHttpService } from '@/services/base-http-service'

class ProjectService extends BaseHttpService {
    public async getProject(id: number, includeEpics = false, epicServiceItemRecordStatus = RecordStatus.All): Promise<Project> {
        const uri = `/projects/${id}?includeEpics=${includeEpics}&epicServiceItemRecordStatus=${epicServiceItemRecordStatus}`
        const response = await this.doApiGet<Project>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProjects(projectRecordStatus = RecordStatus.All, projectKeysToExclude: string[] = [], includeEpics = false, epicServiceItemRecordStatus = RecordStatus.All, userId: number|null = null): Promise<Project[]> {
        const uri = `/projects?projectRecordStatus=${projectRecordStatus}&includeEpics=${includeEpics}&epicServiceItemRecordStatus=${epicServiceItemRecordStatus}${projectKeysToExclude.length ? 
            `&projectKeysToExclude=${JSON.stringify(projectKeysToExclude)}` : ''}${userId ? `&userId=${userId}` : ''}`
        const response = await this.doApiGet<Project[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getProjectSprintsAndBacklog(id: number, month: number, year: number): Promise<ProjectSprintsAndBacklog> {
        const uri = `/projects/${id}?withTickets=true&month=${month}&year=${year}`
        const response = await this.doApiGet<ProjectSprintsAndBacklog>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateProject(partialProject: Partial<Project>): Promise<Project> {
        const uri = `/projects/${partialProject.id}`
        const response = await this.doApiPut<Project>(uri, JSON.stringify({ partialProject }))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async inactivateProject(id: number): Promise<boolean> {
        const uri = `/projects/${id}/inactivate`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async loadProjects(): Promise<boolean> {
        const uri = '/projects/load'
        const response = await this.doApiPost<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateProjectUsers(projectId: number): Promise<boolean> {
        const uri = `/projects/${projectId}/update-project-users`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async removeUnusedProjectUsers(projectId: number): Promise<boolean> {
        const uri = `/projects/${projectId}/remove-unused-project-users`
        const response = await this.doApiPut<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getEpicServiceItems(projectId: number, activeOnly = true): Promise<{ name: string; id: number }[]> {
        const uri = `/projects/${projectId}/epic-service-items?activeOnly=${activeOnly}`
        const response = await this.doApiGet<{ name: string; id: number }[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new ProjectService()
